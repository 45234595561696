$screen-xxs: 360px;
$screen-xs: 375px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1920px;

$custom-gray: whitesmoke;
$color-primary: #000000;
$color-secondary: #289d50;
$color-error: #ff0000;

@mixin absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin container($width) {
    max-width: $width;
    width: 100%;
}

@mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin sticky__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

@mixin maxlines($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
