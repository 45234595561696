@import "../../../styles/variables.scss";

@mixin footer-container {
    // @include sticky__footer();
    border-top: 1px solid whitesmoke;
    background-color: white;
    padding: 1em;
    display: flex;
}

.sticky__footer {
    @include sticky__footer();
    @include footer-container();
}

.footer {
    @include footer-container();
}

.topping__category {
    background-color: whitesmoke;
    padding: 0.5em 1em;
    span {
        font-weight: 700;
        font-size: large;
    }
}

.topping__category__scroll {
    scroll-margin: 5em;
}

.title {
    margin-left: 0.5em;

    font-weight: 900;
    font-size: 24px;

    @media (max-width: $screen-xs) {
        font-size: 20px;
    }
}

.cart__btn {
    width: 100%;

    @media (max-width: $screen-sm) {
        margin-bottom: 1em;
    }

    .price {
        position: absolute;
        right: 0;
        margin-right: 16px;
        @media (max-width: $screen-sm) {
            display: none;
        }
    }
}

.fab__container {
    display: flex;
    align-items: center;
    text-align: center;

    h2 {
        margin: 0 0.5em;
    }

    height: 50px;
    margin-right: 1em;
}

.fab {
    background-color: whitesmoke;
}

.special_instructions_container {
    margin-bottom: 7em;
}
