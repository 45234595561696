@import "../../styles/variables.scss";

$bg: #f3f4f6;

@mixin shadow {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.container {
    @media (min-width: $screen-sm) {
        padding-top: 1rem;
    }
}

.half__bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-color: $bg;
}

.img__container {
    max-width: $screen-lg;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-sm) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    @media (min-width: $screen-lg) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.img__sub__container {
    @include shadow();
    border-radius: 1rem;
    position: relative;
    overflow: hidden;

    @media (max-width: $screen-sm) {
        border-radius: 0;
    }
}

.img__wrapper {
    @include absolute();
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img__bg {
    @include absolute();
    background-color: rgb(0 0 0 / 0.3);
}

.text__container {
    position: relative;
    padding: 4rem 1rem;

    @media (min-width: $screen-sm) {
        padding: 5rem 1.5rem;
    }

    @media (min-width: $screen-lg) {
        padding: 7rem 2rem;
    }
}

.h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: white;
    text-align: center;

    font-weight: 900;
    letter-spacing: -0.025em;

    @media (min-width: $screen-sm) {
        font-size: 3rem;
        line-height: 1;
    }

    @media (min-width: $screen-lg) {
        font-size: 3.75rem;
        line-height: 1;
    }
}

.logo__container {
    position: relative;
    text-align: center;
}
.logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    border: 1rem solid white;
}

.bottom__container {
    display: flex;
    max-width: $screen-lg;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    overflow: auto;
}

.bottom__container__loading {
    background-color: $bg;
    height: 4.25rem;
}

.text {
    max-width: fit-content;
    text-align: center;

    span {
        letter-spacing: 0.025em;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        font-weight: 800;
    }
    p {
        color: #505864;
    }
}

.info__button {
    z-index: 1;
}
